<template>
  <b-row class="ml-4 main">
    <b-col>
      <b-alert v-model="success.model" variant="success" dismissible fade>
        {{ success.text }}
      </b-alert>
      <b-alert v-model="alert.model" variant="danger" dismissible fade>
        {{ alert.text }}
      </b-alert>
      <b-card>
        <b-form>
          <b-form-group label="Organization" label-size="lg" style="max-width: 40rem">
            <OrganizationSelectV2 @organizationSelected="organizationSelected" />
          </b-form-group>
          <div v-if="canEditMc">
            <div v-if="selectedOrganization">
              <b-form-group label="Raffle" label-size="lg">
                <EventSelectV2
                  :organizationId="selectedOrganization.id"
                  :activeOnly="false"
                  @eventSelected="eventSelected"
                  style="max-width: 40rem"
                />
              </b-form-group>
              <b-form-group label="Email Template" label-size="lg">
                <b-select v-model="selectedTemplate" style="max-width: 20rem">
                  <b-select-option :value="{ niceName: 'Raffle Reminder', value: 'raffleReminder' }"
                    >Raffle Reminder</b-select-option
                  >
                  <b-select-option :value="{ niceName: 'Winner Picked', value: 'winnerPicked' }"
                    >Winner Picked</b-select-option
                  >
                  <b-select-option :value="{ niceName: 'Event Member Link', value: 'eventMemberLink' }">
                    Event Member Link
                  </b-select-option>
                </b-select>
              </b-form-group>
              <b-form-group v-if="selectedTemplate.value === 'eventMemberLink'" label="Event Member" label-size="lg">
                <EventMemberSelectV2
                  v-model="selectedEventMember"
                  :eventId="selectedEvent.id"
                  :defaultOption="{ name: 'Everyone', id: '' }"
                />
              </b-form-group>
              <b-form-group v-if="selectedTemplate.value !== 'eventMemberLink'" label="Email Settings" label-size="lg">
                <!-- This option cannot be re-enabled until the MC backend can support it using Postgres -->
                <!-- <b-form-checkbox v-model="useCustomEventMemberLinks" class="mt-1" switch
                  >Use custom event member links
                </b-form-checkbox> -->
                <b-form-checkbox
                  v-if="selectedTemplate.value === 'winnerPicked'"
                  v-model="includeNextRaffleLink"
                  class="mt-1"
                  switch
                  >Include next raffle link
                </b-form-checkbox>
                <b-form-checkbox
                  v-if="selectedTemplate.value === 'raffleReminder'"
                  v-model="omitPurchasedForEvent"
                  class="mt-1"
                  switch
                  >Exclude customers who have already purchased for the event.
                </b-form-checkbox>
              </b-form-group>

              <b-form-group label="Message Center Settings" label-size="lg">
                <b-form-checkbox v-model="messageCenterEnabled" class="mt-1 mb-2" switch
                  >Enable Message Center for Organization.
                </b-form-checkbox>
              </b-form-group>
              <b-form-group
                v-if="includeNextRaffleLink"
                label="Next Raffle Link"
                label-for="input-next-raffle-link"
                :invalid-feedback="veeErrors.first('input-next-raffle-link')"
              >
                <b-form-input
                  name="input-next-raffle-link"
                  v-model="nextRaffleLink"
                  v-validate="{ required: true, url: { require_protocol: true } }"
                  :state="validateState('input-next-raffle-link')"
                  aria-describedby="input-next-raffle-link-feedback"
                  data-vv-as="next raffle link"
                  trim
                />
              </b-form-group>
              <b-form-group>
                <div>
                  <b-button variant="outline-secondary" :disabled="!selectedEvent" v-b-modal.send-email-modal>
                    Send Email
                  </b-button>
                  <b-button
                    style="margin-left: 1rem"
                    variant="outline-secondary"
                    :disabled="!selectedEvent"
                    v-b-modal.send-test-email-modal
                  >
                    Send Test Email
                  </b-button>
                  <SendEmailModal
                    :organization="selectedOrganization"
                    :event="selectedEvent"
                    :eventMember="selectedEventMember"
                    :template="selectedTemplate"
                    :settings="settings"
                    @success="handleSuccess"
                    @error="handleError"
                  />
                </div>
                <SendTestEmailModal
                  :event="selectedEvent"
                  :eventMember="selectedEventMember"
                  :template="selectedTemplate"
                  :settings="settings"
                  @success="handleSuccess"
                  @error="handleError"
                />
              </b-form-group>
            </div>
          </div>
        </b-form>
      </b-card>
      <MessageCenterHistoryList
        :organization="selectedOrganization"
        ref="history"
        style="margin-top: 2rem"
        @error="handleError"
      />
    </b-col>
  </b-row>
</template>

<script>
import config from '@/config';
import EventSelectV2 from '@/components/EventSelectV2';
import OrganizationSelectV2 from '@/components/OrganizationSelectV2';
import SendEmailModal from '@/components/SendEmailModal';
import SendTestEmailModal from '@/components/SendTestEmailModal';
import EventMemberSelectV2 from '@/components/EventMemberSelectV2';
import MessageCenterHistoryList from '@/components/MessageCenterHistoryList';
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  components: {
    EventSelectV2,
    OrganizationSelectV2,
    SendEmailModal,
    SendTestEmailModal,
    EventMemberSelectV2,
    MessageCenterHistoryList
  },
  data() {
    return {
      selectedEvent: null,
      selectedOrganization: null,
      selectedTemplate: { niceName: 'Raffle Reminder', value: 'raffleReminder' },
      selectedEventMember: null,
      eventMembers: [],
      useCustomEventMemberLinks: false,
      includeNextRaffleLink: false,
      nextRaffleLink: undefined,
      omitPurchasedForEvent: true,
      messageCenterEnabled: undefined,
      success: {
        text: '',
        model: false
      },
      alert: {
        text: '',
        model: false
      },
      canEditMc: false
    };
  },
  async created() {
    this.canEditMc = await unleashFeatureEnabled(UnleashKeys.EditMessageCenter);
  },
  watch: {
    async messageCenterEnabled() {
      await OrganizationServiceV2.updateOrganization(this.selectedOrganization.id, {
        settings: {
          messageCenterEnabled: this.messageCenterEnabled
        }
      });
    }
  },
  computed: {
    showEditMc() {
      return this.canEditMc;
    },
    settings() {
      return {
        useCustomEventMemberLinks: this.useCustomEventMemberLinks,
        includeNextRaffleLink: this.includeNextRaffleLink,
        nextRaffleLink: this.nextRaffleLink,
        omitPurchasedForEvent: this.omitPurchasedForEvent && this.selectedTemplate.value === 'raffleReminder',
        messageCenterEnabled: this.messageCenterEnabled
      };
    }
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    async organizationSelected(organization) {
      this.selectedOrganization = organization;

      if (this.selectedOrganization) {
        try {
          this.nextRaffleLink = `${config.RAFFLEBOX_URL}/raffle/${organization.shortlink}`;
          this.messageCenterEnabled = this.selectedOrganization.settings.messageCenterEnabled;
        } catch (error) {
          const errorMessage = error.response ? error.response.data.errors[0].message : error;
          this.handleError(`Failed to change organization: ${errorMessage}`);
        }
      }
    },
    eventSelected(event) {
      this.selectedEvent = event;
    },
    handleSuccess(text) {
      this.success.text = text;
      this.success.model = true;

      this.$refs.history.refreshTable();
    },
    handleError(text) {
      this.alert.text = text;
      this.alert.model = true;
    }
  }
};
</script>

<style scoped>
.main {
  text-align: left;
  margin-top: 20px;
  margin-left: 115px;
}
</style>
